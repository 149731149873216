import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import Layout from "../components/Layout";
import Logo from "../assets/images/logo.png";
import { AppContext } from '../services/AppContext'


const Entry = () => {
  let history = useHistory();
  const { entryId } = useParams();
  const {
    getEntry
  } = useContext(AppContext);

  const [entryName, setEntryName] = useState('');
  const [entryError, setEntryError] = useState(false);
  const [entryUrl, setEntryUrl] = useState('');

  useEffect(()=>{
    getEntry(entryId)
      .then( (entry) => {
        // setEntryName(entry.name);
        if (!entry) {
          setEntryError(true);
        } else {
          setEntryName(entry.name);
          setEntryUrl(entry.url);
        }
      });
  },[]);

  if (entryError) {
    return (
      <Layout>
        <div className="grid grid-cols-1 md:grid-cols-2">
          <div className="p-6 flex flex-col flex-1 h-full justify-between">
            <h2 className="font-bold text-xl md:text-3xl uppercase">
              Invalid entry
            </h2>
            <p>
              The contest entry you're trying to access does not exist or has not been accepted.
            </p>
          </div>
        </div>
      </Layout>
    )
  }

  return (
    <Layout>
      <div className="grid grid-cols-1 md:grid-cols-2">
        <div className="p-6 flex flex-col flex-1 h-full justify-between">
          <div className="text-slate flex justify-between items-center">
            <div>
              <img alt="" src={Logo} />
            </div>
            <div className="uppercase text-xl md:text-3xl -mt-1">
              {entryName}
            </div>
          </div>
          
          <button onClick={history.goBack}
            className={`border-2 border-red text-red px-5 py-4 text-lg uppercase mx-auto font-bold inline-block`}
          >
            BACK
          </button>
        </div>
        <div className="p-6 bg-lightGray">
          <img src={entryUrl} className="relative top-0 z-6" alt="" />
        </div>
      </div>
    </Layout>
  );
};

export default Entry;
