import React, { useContext, useEffect, useState } from "react";
import { AppContext } from '../services/AppContext'
import Layout from "../components/Layout";
import ModalWrapper from "../components/ModalWrapper";
import Logo from "../assets/images/logo.png";
import ContestImage from "../components/ContestImage";
import { forEach } from "async";

const SignUpForm = () => {
  const { signUp, signUpError } = useContext(AppContext);
  const [showModal, setShowModal] = useState(false);
  
  const initialField = {value: '', error: false};
  const [nameField, setNameField] = useState(initialField);
  const [emailField, setEmailField] = useState(initialField);
  const [phoneField, setPhoneField] = useState(initialField);
  const [termsField, setTermsField] = useState({value: false, error: false});
  
  const validateFields = () => {
    let valid = true;
    if (nameField.value === '') {
      setNameField({
        value: '',
        error: true,
      });
      valid = false;
    }
    if (emailField.value === '' || !validEmail(emailField.value)) {
      setEmailField({
        value: emailField.value,
        error: true,
      });
      valid = false;
    }
    if (!termsField.value) {
      setTermsField({value: false, error: true});
      valid = false;
    }
    return valid;
  }
  const handleSubmit = (ev) => {
    ev.preventDefault();
    if (validateFields()) {
      signUp({
        name: nameField.value,
        email: emailField.value,
        phone: phoneField.value,
      });
    }
  };
  const validEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };
  const termsModal = () => {
    return (
      <ModalWrapper
        show={showModal}
        close={() => setShowModal(false)}
        className="max-w-screen-md"
      >
        <div className="text-center ">
          <div className="text-slate space-y-6 ">
            <div className="flex justify-around items-center">
              <div>
                <img alt="" src={Logo} />
              </div>
              <h2 className="text-slate font-bold text-xl md:text-3xl uppercase">
                Terms and Conditions for the Photo Content
              </h2>
            </div>
            <div className="p-6 border border-gray-200 ">
              <div className="text-coolGrey text-left h-96 overflow-auto space-y-2">
                <p>All photos must be original work, taken by the entrants.</p>
                <p>
                  No third party may own or control any materials the photo
                  contains, and the photo must not infringe upon the trademark,
                  copyright, moral rights, intellectual rights, or rights of
                  privacy of any entity or person. The photo must be in its
                  original state and cannot be altered in any way, including but
                  not limited to removing, adding, reversing, or distorting
                  subjects within the frame. Violators will be removed from the
                  contest, stripped of any prize(s), and banned from entering
                  future contests. Entries will not be accepted unless submitted
                  via the official contest channel.
                </p>
                <p>
                  Entries not submitted through the proper channel will be
                  deleted. Entries will be judged by Oklahoma City Dodgers. All
                  decisions are final. The Company reserves the right to
                  disqualify any entry that is deemed inappropriate or does not
                  conform to stated contest rules.
                </p>
                <p>
                  By entering the contest, entrants agree that photos submitted
                  can be used by Oklahoma City Dodgers for advertising purposes.
                </p>
                <p>
                  Submissions will not be accepted once the deadline lapses.
                </p>
                <p>
                  The prize must be collected by the winner and is
                  nontransferable. The winner will be contacted via the email
                  address provided during entry. If no response is received
                  after [three] business days, a new winner will be selected and
                  the previous winner will forfeit all rights to the prize.{" "}
                </p>
                <p>
                  The contest is void where prohibited or restricted by law.{" "}
                </p>
                <p>
                  If you’re using entry forms like those offered by Submittable,
                  you can include language at end of the form asking entrants to
                  confirm that they have read the rules and regulations by
                  clicking the submit button.
                </p>
              </div>
            </div>

            <div>
              <button
                onClick={() => {
                  setTermsField({
                    value: true,
                    error: false,
                  })
                  setShowModal(false);
                }}
                className={`bg-red text-white px-3 py-2 text-lg uppercase mx-auto font-bold inline-block`}
              >
                I agree
              </button>
            </div>
          </div>
        </div>
      </ModalWrapper>
    )
  };

  return (
    <Layout>
      { termsModal() }    
      <div className="grid grid-cols-1 md:grid-cols-2">
        <form>
          <div className="p-6 flex flex-col flex-1 h-full justify-between">
            <div className="text-slate flex justify-between items-end">
              <div>
                <img alt="" src={Logo} />
              </div>
            </div>
            <h2 className="text-slate font-bold text-xl md:text-3xl my-4 uppercase">
              Submit your Halloween PHOTO!
            </h2>
            <p className="text-black text-base md:text-lg text-coolGrey pr-6">
              Enter your contact information and accept the terms and conditions
              of the contest to participate. <br /> Our team will review and
              approve the photo so you can share it. Get the votes, get the
              prize!
            </p>
            <div className="pr-6">
              <div className="space-y-6 mt-4">
                <div>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    value={nameField.value}
                    placeholder="NAME"
                    className="form-input w-full"
                    onChange={(ev) => setNameField({
                      value: ev.target.value,
                      error: false,
                    })}
                  />
                  {nameField.error && (
                    <small className="text-red">
                      Name is required
                    </small>
                  )}
                </div>

                <div>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    onChange={(ev) => setEmailField({
                      value: ev.target.value,
                      error: false,
                    })}
                    value={emailField.value}
                    placeholder="EMAIL"
                    className="form-input w-full"
                  />
                  {emailField.error && (
                    <small className="text-red">
                      A valid email is required
                    </small>
                  )}
                </div>
                <div>
                  <input
                    type="number"
                    name="phone"
                    id="phone"
                    value={phoneField.value}
                    placeholder="PHONE"
                    onChange={(ev) => setPhoneField({value: ev.target.value, error: false})}
                    className="form-input w-full"
                  />
                </div>
                <div>
                  <label className="text-sm">
                    <input
                      type="checkbox"
                      name="terms"
                      id="terms"
                      placeholder="PHONE"
                      className="form-input mr-2"
                      value="1"
                      checked={termsField.value}
                      onChange={(ev) =>
                        setTermsField({
                          value: ev.target.checked,
                          error: false,
                        })
                      }
                    />
                    I accept{" "}
                    <button
                      className="text-lightBlue"
                      type="button"
                      onClick={(ev) => {
                        ev.preventDefault();
                        setShowModal(true);
                      }}
                    >
                      Terms and Conditions
                    </button>
                  </label>
                  {termsField.error && (
                    <small className="block text-red">
                      Terms are required
                    </small>
                  )}
                </div>
              </div>
            </div>

            <div className="text-center mt-3">
              <p className="text-red">
                {/* This can be changed for a material Alert */}
                {signUpError}
              </p>
              <button
                type="button"
                disabled={false}
                onClick={(ev) => {
                  handleSubmit(ev);
                }}
                className={`bg-red text-white px-3 py-2 text-lg uppercase mx-auto font-bold inline-block`}
              >
                Share my photo
              </button>
            </div>
          </div>
        </form>
        <div className="p-6 flex flex-col justify-between items-center space-y-4 md:space-y-0 bg-lightGray">
          <ContestImage />
        </div>
      </div>
    </Layout>
  );
};

export default SignUpForm;
